import { defineStore } from 'pinia';

export const useAppStore = defineStore('m-app', {
	namespaced: true,

	state: () => ({
		isEmbedded: false,
		isHybrid: false,
		isHybridSky: false,
		isIOS: false,
		companyId: 'FA',
		onboardingUrl: '',
		telephone: '+34 913 086 428 / +34 900 107 901',
		email: 'comunicaciones-medseguros@cbnk.es',
		cookiesPolicyLink: 'https://www.cbnk.es/aviso-legal#politica-de-cookies',
		bizumSafetyAdvice: '',
		measurementId: '',
		legalIdentity: '',
		legalDisclaimerLink: 'http://www.cbnk.es/aviso-legal',
		liveagent: {},
	}),

	actions: {
		setIsEmbedded() {
			this.isEmbedded = true;
		},
	},

	getters: {
		getCompanyId() {
			return this.companyId;
		},
		getIsEmbedded() {
			return this.isEmbedded;
		},
		getIsHybrid() {
			return this.isHybrid;
		},
		getIsHybridSky() {
			return this.isHybridSky;
		},
	},
});
