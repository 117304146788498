import {
	USER_INVALID_CRED,
	USER_NOT_FOUND,
	USER_WILL_BE_TEMP_BLOCKED,
	USER_WAS_TEMP_BLOCKED,
	USER_WILL_BE_PERMANENTLY_BLOCKED,
	REMEMBER_TOKEN_INVALID,
} from '@modules/service/constants';

export const useAuthStore = defineStore('m-authn', {
	state: () => ({
		isLoggedIn: false,
	}),

	getters: {
		getIsLoggedIn() {
			return this.isLoggedIn;
		},
	},

	actions: {
		async login({ username, password, rememberToken, isMyDevice }) {
			await useSecureStore().createSession();
			useContractsStore().reset();
			useLoadingStore().start();

			const url = '/login';
			const method = 'POST';
			const payload = {
				password,
				channel: 'WEB',
				companyId: useAppStore().companyId,
				deviceId: useDeviceStore().id,
				isMyDevice,
			};

			if (rememberToken && !username) {
				Object.assign(payload, { rememberToken });
			} else {
				Object.assign(payload, { documentId: username });
			}

			return new Promise((resolve, reject) => {
				useServiceStore()
					.request({
						service: {
							request: {
								url,
								method,
							},
						},
						payload,
					})
					.then(async ({ data }) => {
						if (data?.requirePwdChange) {
							const MPasswordChange = await import('@modals/m-cbnk-password-change.vue');
							const pwdChangeResponse = await useModalStore().open({
								component: MPasswordChange,
							});

							if (!pwdChangeResponse) {
								throw pwdChangeResponse;
							}
						}
						return this.getContracts({
							data: { username: data?.username },
						}).then(() => {
							this.isLoggedIn = true;

							return resolve(data);
						});
					})
					.catch((error) => {
						useLoadingStore().end();

						const reasons = {
							BAD_CREDENTIALS: 1 << 0,
							BAD_PASSWORD: 1 << 1,
							BAD_USER: 1 << 2,
						};

						const reason = { ...reasons };
						const { data = {} } = error?.response ?? {};

						reason.status = reasons.BAD_PASSWORD;

						if (data.errorCode === 'CHANGE_USER') {
							reason.status = reasons.BAD_USER;
						}

						if (data.errorCode === REMEMBER_TOKEN_INVALID) {
							reason.status = reasons.BAD_USER | reasons.BAD_CREDENTIALS;
						}

						if (
							data.errorCode === USER_INVALID_CRED ||
							data.errorCode === USER_NOT_FOUND ||
							data.errorCode === USER_WILL_BE_TEMP_BLOCKED ||
							data.errorCode === USER_WAS_TEMP_BLOCKED ||
							data.errorCode === USER_WILL_BE_PERMANENTLY_BLOCKED
						) {
							reason.status |= reasons.BAD_CREDENTIALS;
						}

						this.isLoggedIn = false;

						reject(reason);
					});
			});
		},

		loginAnonymous() {
			const url = '/login';
			const method = 'POST';

			return useServiceStore().request({
				service: {
					request: {
						url,
						method,
					},
				},
				payload: {
					companyId: useAppStore().companyId,
					deviceId: useDeviceStore().id,
					channel: 'WEB',
				},
			});
		},

		async getContracts({ data, source, origin }) {
			const response = await useContractsStore().get();
			const { contracts = [] } = response;
			const [contract] = contracts;
			const userUUID = useSecureStore().uuid;
			const userId = data?.userId;

			if (!contract) {
				return Promise.reject();
			}

			if (contract && source && origin) {
				source.postMessage(
					{
						name: 'show-frame',
						userUUID,
						userId,
					},
					origin
				);
			}

			this.isLoggedIn = true;

			return useContractsStore().set(contract);
		},

		async logout() {
			const isEmbedded = useAppStore().isEmbedded;

			if (isEmbedded) {
				const userUUID = useSecureStore().uuid;

				if (window.parent && userUUID) {
					window.parent.postMessage(
						{
							name: 'close-session',
							userUUID,
						},
						'*'
					);
				}
			}
			await useModalStore().closeAll();
			await useUserStore().clearCache();
			await useSecureStore().removeSession();

			this.isLoggedIn = false;

			useLoadingStore().end();
		},

		async activeLogout() {
			const isEmbedded = useAppStore().isEmbedded;
			const component = await import('@modals/m-logout');
			const isConfirm = await useModalStore().open(component);

			/* istanbul ignore else */
			if (isConfirm) {
				if (!isEmbedded) {
					await useSessionStore().deleteSession();
				}

				/* istanbul ignore next */
				if (navigator.credentials && navigator.credentials.preventSilentAccess) {
					navigator.credentials.preventSilentAccess();
				}

				await this.logout();
			}
		},

		async passiveLogout() {
			await this.logout();

			const component = await import('@modals/m-expired-session');

			await useModalStore().open(component);
		},

		refresh() {
			const url = '/keep-alive';
			const method = 'GET';

			return useServiceStore()
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(() => {})
				.catch(() => {});
		},

		async createUserSession({ data, source, origin }) {
			await useSecureStore().createSession();
			await useSessionStore().setUserSession({ userName: data?.username });

			const url = '/associate-uuids';
			const method = 'POST';
			const { agentUUID, userId } = data;
			const userUUID = useSecureStore().uuid;

			return useServiceStore()
				.request({
					service: {
						headers: {
							'Content-Type': 'application/json',
							'uuid': agentUUID,
							'childuuid': userUUID,
						},
						request: {
							url,
							method,
						},
					},
				})
				.then(() => {
					source.postMessage(
						{
							name: 'session-is-ready',
							userUUID,
							userId,
						},
						origin
					);
				})
				.catch(() => {
					source.postMessage(
						{
							name: 'error',
							userUUID,
							userId,
							text: 'DASHBOARD.SOMETHING_WRONG',
						},
						origin
					);
				});
		},

		authorizeAccess({ data, source, origin }) {
			useLoadingStore().start();

			const url = '/assisted-channels/impersonations';
			const method = 'POST';
			const { userId } = data;
			const userUUID = useSecureStore().uuid;

			return useServiceStore()
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { userId },
				})
				.then(() =>
					this.getContracts({
						data,
						source,
						origin,
					})
				)
				.catch(() =>
					source.postMessage(
						{
							name: 'error',
							userUUID,
							userId,
							text: 'DASHBOARD.SOMETHING_WRONG',
						},
						origin
					)
				);
		},
	},
});
