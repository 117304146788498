import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

import { i18n } from '../../plugins/i18n.js';
import { makeServer, makeServerForCypress } from '@plugins/server';

import { onCLS, onFCP, onINP, onLCP, onTTFB } from 'web-vitals';
import router from './router';
import App from './App.vue';
import directives from '@directives';

import { formatCurrency } from '@utils/formatCurrency';
import { formatProductNumber } from '@utils/numberFormatter';

import mitt from 'mitt';

import registerSW from '@/registerServiceWorker.js';

registerSW();

if (import.meta.env.VITE_APP_ENDPOINT_MODE === 'mck') {
	makeServer();
}

const app = createApp(App);

if (window.Cypress) {
	Cypress.on(
		'uncaught:exception',
		() =>
			// returning false here prevents Cypress from
			// failing the test
			false
	);

	makeServerForCypress();
}

// Envía las métricas de rendimiento a google analytics.
if (window.dataLayer) {
	const sendToGoogleAnalytics = ({ name, delta, value, id }) => {
		window.dataLayer.push({
			event: 'coreWebVitals',
			webVitalsMeasurement: {
				name,
				id,
				value,
				delta,
				valueRounded: Math.round(name === 'CLS' ? value * 1000 : value),
				deltaRounded: Math.round(name === 'CLS' ? delta * 1000 : delta),
			},
		});
	};

	onCLS(sendToGoogleAnalytics);
	onFCP(sendToGoogleAnalytics);
	onINP(sendToGoogleAnalytics);
	onLCP(sendToGoogleAnalytics);
	onTTFB(sendToGoogleAnalytics);
}

Object.entries(directives).forEach(([name, directive]) => {
	app.directive(name, directive);
});

app.config.globalProperties.$nc = formatCurrency;
app.config.globalProperties.$pn = formatProductNumber;

const pinia = createPinia();

const emitter = mitt();

pinia.use(({ store }) => {
	store.$emitter = emitter;
});

app.use(pinia);

app.use(i18n);

app.use(router);

app.use(VCalendar);

app.mount('#app');

// Simular Vuex 'dispatch' en la propiedad global $store para cualquier acción en hibridacion
const store = {
	dispatch(action, payload) {
		const [storeName, methodName] = action.split('/'); // Separamos el store y la acción
		const realStoreName = `m-${storeName}`;
		const store = pinia._s.get(realStoreName);
		if (typeof store[methodName] === 'function') {
			store[methodName](payload);
		}
	},
};

window.__app__ = { ...app, $store: store };
