const USER_SESSION_KEY = 'USER_SESSION';
const THEME_SESSION_KEY = 'themeSession';
const SKIP_NEWS_KEY = 'SKIP_NEWS';

export const useSessionStore = defineStore('m-session', {
	namespaced: true,

	state() {
		const userSession = JSON.parse(localStorage.getItem(USER_SESSION_KEY)) || {};
		const themeSession = JSON.parse(localStorage.getItem(THEME_SESSION_KEY)) || {};
		const isNewsOmitted = localStorage.getItem(SKIP_NEWS_KEY) || '';
		const { userName = '', lastLogin = '', rememberToken = '' } = userSession;
		const { theme = 'light' } = themeSession;

		return {
			lang: 'es',
			theme,
			userName,
			lastLogin,
			rememberToken,
			isNewsOmitted,
		};
	},

	getters: {
		getRememberToken() {
			return this.rememberToken;
		},
		getUserName() {
			return this.userName;
		},
		getLang() {
			return this.lang;
		},
	},

	actions: {
		rememberUserSession({ userName, lastLogin, rememberToken }) {
			this.setUserSession({ userName, lastLogin });
			this.setRememberToken(rememberToken);

			localStorage.setItem(
				USER_SESSION_KEY,
				JSON.stringify({
					userName,
					lastLogin,
					rememberToken,
				})
			);
		},

		removeUserSession() {
			this.userName = '';
			this.lastLogin = '';

			this.rememberToken = null;

			sessionStorage.removeItem('SECURE_SESSION');
		},

		forgetUserSession() {
			localStorage.removeItem(USER_SESSION_KEY);
		},

		setAppTheme(theme) {
			this.setTheme(theme);
			localStorage.setItem(THEME_SESSION_KEY, JSON.stringify({ theme }));
		},

		changeLanguage(lang) {
			this.lang = lang;

			// To notify backend the language change so that if any documents are downloaded these will be translated.
			const method = 'PATCH';
			const url = '/current/user/language';

			return useServiceStore().request({
				service: {
					request: {
						url,
						method,
					},
				},
				payload: { language: lang },
			});
		},

		skipNews(version) {
			this.isNewsOmitted = version;

			localStorage.setItem(SKIP_NEWS_KEY, version);
		},

		deleteSession() {
			const method = 'DELETE';
			const url = '/session';

			return useServiceStore().request({
				service: {
					request: {
						url,
						method,
					},
				},
			});
		},

		deleteCache() {
			const method = 'DELETE';
			const url = '/cache';

			return useServiceStore().request({
				service: {
					request: {
						url,
						method,
					},
				},
			});
		},

		setUserSession({ userName, lastLogin }) {
			this.userName = userName;
			this.lastLogin = lastLogin;
		},

		setRememberToken(data) {
			this.rememberToken = data;
		},

		removeRememberToken() {
			this.rememberToken = null;
		},

		setTheme(value) {
			this.theme = value;
		},
	},
});
