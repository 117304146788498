<template>
	<article class="c-contact-support-info">
		<div class="c-contact-support-info__container">
			<c-icon
				class="c-contact-support-info__icon"
				src="@icons/phone"
				size="xl"
			/>
			<p class="c-contact-support-info__text">
				{{ $t('CONTACT_SUPPORT.CONTACT') }}
			</p>
			<p class="c-contact-support-info__number text-l-medium">
				{{ telephone }}
			</p>
			<p class="c-contact-support-info__bold">
				{{ $t('CONTACT_SUPPORT.WINTER_TIME') }}
			</p>
			<p class="c-contact-support-info__text">
				{{ $t('CONTACT_SUPPORT.TELEPHONE_TIME_WINTER') }}
			</p>
			<p class="c-contact-support-info__bold">
				{{ $t('CONTACT_SUPPORT.SUMMER_TIME') }}
			</p>
			<p class="c-contact-support-info__text">
				{{ $t('CONTACT_SUPPORT.TELEPHONE_TIME_SUMMER') }}
			</p>
			<p class="c-contact-support-info__text">
				{{ email }}
			</p>
		</div>
	</article>
</template>

<script>
import { mapState } from 'pinia';
import CIcon from '@components/c-icon';

export default {
	name: 'c-contact-support-info',

	components: {
		CIcon,
	},

	computed: {
		...mapState(useAppStore, ['telephone', 'email']),
	},
};
</script>

<style lang="scss" scoped>
.c-contact-support-info {
	color: RGB(var(--color-primary-light));
	display: flex;
	position: relative;
	justify-content: center;
	width: 100%;
	max-width: 426px;
	flex-wrap: wrap;
	border: 1px solid RGB(var(--color-surface-dark));
}

.c-contact-support-info__container {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	padding: var(--spacing-l);
	text-align: center;
}

.c-contact-support-info__icon,
.c-contact-support-info__text:not(:last-of-type),
.c-contact-support-info__number,
.c-contact-support-info__bold {
	margin-bottom: var(--spacing-s);
}

.c-contact-support-info__text {
	white-space: pre-line;
	@extend %typo-m-book;
}

.c-contact-support-info__bold {
	font-weight: var(--font-weight-bold);
	font-family: var(--font-family);
	font-size: var(--font-size-m);
}

@media ($m-screen) {
	.c-contact-support-info {
		flex-wrap: nowrap;
	}
}
</style>
