export const useContractsStore = defineStore('m-contracts', {
	state: () => ({
		connectedContract: null,
	}),

	actions: {
		reset() {
			this.connectedContract = null;
		},
		get() {
			return useServiceStore()
				.request({
					service: {
						request: {
							url: '/contracts',
							method: 'GET',
						},
					},
				})
				.then(({ data }) => data)
				.catch(/* istanbul ignore next */ (err) => err);
		},

		set(contract) {
			const { connectedContract } = this;

			if (connectedContract?.id === contract?.id) {
				return;
			}

			const { id } = contract;
			const url = `/contracts/${id}`;
			const method = 'PATCH';

			return useServiceStore()
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(({ data }) => {
					this.connectedContract = data;

					return data;
				})
				.catch(/* istanbul ignore next */ (err) => err);
		},
	},
});
