export default [
	{
		path: '/:pathMatch(.*)*', // Cambiado para Vue Router 4
		redirect: { name: 'login' },
	},
	{
		path: '/',
		name: 'login',
		components: {
			default: () => import('@fam/views/v-login.vue'),
		},
	},
	{
		path: '/blocking-communication/',
		name: 'blocking-communication',
		props: { default: true },
		components: {
			default: () => import('@fam/views/v-blocking-communication'),
		},
		children: [
			{
				path: 'gdpr',
				name: 'gdpr',
				props: { default: true },
				components: {
					default: () => import('@fam/views/v-gdpr-management'),
				},
			},
			{
				path: 'acceptance',
				name: 'acceptance',
				components: {
					default: () => import('@fam/views/v-acceptances'),
				},
			},
		],
	},
	{
		path: '/main/',
		name: 'main',
		components: {
			default: () => import('@fam/views/v-main.vue'),
		},
		children: [
			{
				path: 'products',
				name: 'products',
				components: {
					default: () => import('@fam/views/v-products.vue'),
				},
			},
			{
				path: 'signatures',
				name: 'signatures',
				components: {
					default: () => import('@fam/views/v-signatures.vue'),
				},
			},
			{
				path: 'signatures/detail/:signatureId/:type',
				name: 'signature-detail',
				props: { default: true },
				components: {
					default: () => import('@fam/views/v-signature-detail.vue'),
				},
			},
			{
				path: 'marketplace',
				name: 'marketplace',
				components: {
					default: () => import('@fam/views/v-marketplace.vue'),
				},
			},
			{
				path: 'marketplace/category/:categoryId/:categoryName?',
				name: 'marketplace-category',
				props: { default: true },
				components: {
					default: () => import('@fam/views/v-marketplace-category'),
				},
			},
			{
				path: 'marketplace/detail/:productId?/:title?',
				name: 'marketplace-detail',
				props: { default: true },
				components: {
					default: () => import('@fam/views/v-marketplace-detail.vue'),
				},
			},
			{
				path: 'products/detail/:productId?',
				name: 'products-detail',
				props: { default: true },
				components: {
					default: () => import('@fam/views/v-products-detail.vue'),
				},
			},
			{
				path: 'personal-area',
				name: 'personal-area',
				components: {
					default: () => import('@fam/views/v-personal-area.vue'),
				},
			},
			{
				path: 'personal-area/personal-data',
				name: 'personal-data',
				components: {
					default: () => import('@fam/views/v-personal-data.vue'),
				},
			},
			{
				path: 'personal-area/security-and-privacy',
				name: 'security-and-privacy',
				components: {
					default: () => import('@fam/views/v-security-and-privacy.vue'),
				},
			},
			{
				path: 'personal-area/security-and-privacy/gdpr-management',
				name: 'gdpr-management',
				components: {
					default: () => import('@fam/views/v-gdpr-management.vue'),
				},
			},
			{
				path: 'personal-area/security-and-privacy/password-management',
				name: 'password-management',
				components: {
					default: () => import('@fam/views/v-password-management.vue'),
				},
			},
		],
	},
];
