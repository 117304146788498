import { createRouter, createWebHistory } from 'vue-router';
import { importLocale } from '@plugins/i18n';
import routes from './routes';
import { useModalStore } from '@/store/modules/modal/m-modal';
import { useAuthStore } from '@/store/modules/auth/m-authn';
import { useSessionStore } from '@/store/modules/session/m-session';

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior: (to, from, savedPosition) =>
		savedPosition || {
			x: 0,
			y: 0,
		},
});

router.onError((error) => {
	if (error.type === 'error') {
		useServiceStore().setOffline(true);
	}
});

router.beforeEach(async (to, from, next) => {
	const publicPages = ['login'];
	const isAuthRequired = !publicPages.includes(to.name);
	const lang = useSessionStore().getLang;
	const isDev = process.env.NODE_ENV === 'development';
	const redirect = {
		name: 'login',
		query: { redirect: to.fullPath },
		replace: true,
	};
	const hasModalInQueue = Boolean(Object.keys(useModalStore().getQueue).length);
	const isLoggedIn = useAuthStore().getIsLoggedIn;

	if (lang) {
		await importLocale(lang);
	}

	// We cancel navigation in offline mode or in blocked navigation
	if (useServiceStore().offline) {
		return;
	}

	// We close any modal if there is a navigation with a modal opened
	if (hasModalInQueue) {
		await useModalStore().closeAll();

		return next(false);
	}

	// We request the exit and logout after navigate to login on embedded version
	if (to?.name === 'login' && useAppStore().isEmbedded) {
		if (isLoggedIn) {
			await useAuthStore().logout();
		}

		return;
	}

	if (isAuthRequired && !isLoggedIn) {
		// Autologin: used only on mocked servers
		/* istanbul ignore next */
		if (isDev) {
			const session = sessionStorage.getItem('SECURE_SESSION');
			if (session) {
				const { rememberToken, password } = JSON.parse(session);

				if (rememberToken && password) {
					return useAuthStore()
						.login({
							rememberToken,
							password,
						})
						.then(({ theme = 'light' }) => useSessionStore().setAppTheme(theme))
						.then(({ username: userName, lastLogin }) =>
							useSessionStore().setUserSession({
								userName,
								lastLogin,
							})
						)
						.then(() => next())
						.catch(() => next(redirect));
				}
			}
		}

		return next(redirect);
	}

	next();
});
let once = false;

router.afterEach(() => {
	if (!once) {
		once = true;

		/**
		 * We don't use the "to" param to avoid redundant navigation error
		 * We create a state on the history
		 */
		if (router.currentRoute?.value?.query?.entryPoint) {
			const to = router.resolve(router.currentRoute.value.query.redirect);

			router.push({
				name: to.name,
				params: to.params,
				query: to.query,
			});
		}
	}
});

export default router;
